<template>
  <div class="mb-3.5 overflow-hidden rounded-xl border border-blue-light bg-white lg:rounded-2xl">
    <button class="flex w-full items-center justify-between p-4 lg:px-6 lg:py-5" @click="openClose">
      <span
        class="w-full text-left font-serif text-xl leading-[1.136] text-black lg:text-xl lg:font-bold lg:leading-snug"
      >
        <slot name="header"></slot>
      </span>
      <PlusBox v-if="!isShow" class="ml-3 h-4 w-4 shrink-0 lg:h-6 lg:w-6" />
      <MinusBox v-else class="ml-3 h-4 w-4 shrink-0 lg:h-6 lg:w-6" />
    </button>

    <div
      class="wrapper grid grid-flow-col border-t border-grey-light bg-white"
      :class="[isShow && 'is-open']"
    >
      <div class="inner overflow-hidden">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import PlusBox from '../atoms/Icons/PlusBox.vue'
import MinusBox from '../atoms/Icons/MinusBox.vue'

const props = defineProps({
  open: { type: Boolean, default: false },
})

const isShow = ref(props.open)

const openClose = () => {
  isShow.value = !isShow.value
}
</script>

<style scoped>
.wrapper {
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.wrapper.is-open {
  grid-template-rows: 1fr;
}
</style>
